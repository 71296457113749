import React, { useEffect, useState } from 'react'
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import { createStore, useStateMachine } from 'little-state-machine'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Header from '../../components/Header'
import { Typography } from '@material-ui/core'
import { getEvent } from '../../utils/queryEvent'

createStore(
  {
    events: [],
  },
  {
    name: '__piper__',
    storageType: localStorage,
  },
)

const getEventDetails = async (events, setOtherEvents) => {
  await setOtherEvents(await Promise.all(events.map(async (event) => {
    const response = await getEvent(event.id)
    return { ...response.data, ...event }
  })))
}

const Home = ({ eventState = {}, setEventState = () => {} }) => {
  const history = useHistory()
  const { state } = useStateMachine()
  const [ otherEvents, setOtherEvents ] = useState([])

  useEffect(() => {
    if (state.events.length) {
      getEventDetails(state.events, setOtherEvents)
    }
  }, [state.events, setOtherEvents])

  const { register, handleSubmit, formState: { errors }, getValues, setValue, watch } = useForm({
    defaultValues: {
      crew: [],
      crewMemberName: null,
      nextCrewId: 0,
    },
  })
  const crew = watch('crew', [])

  const { ref: crewMemberNameRef, ...crewMemberNameProps } = register('crewMemberName')

  const onSubmit = async (values) => {
    const evt = { ...eventState,
      crew: crew.map((crewMember) => ({ name: crewMember.name })),
    }

    if (values.crewMemberName && values.crewMemberName.trim()) {
      evt.crew.push({ name: values.crewMemberName.trim() })
    }

    setEventState(evt)

    history.push('/details')
  }

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="flex-end">
            <div style={{
              flexGrow: 1,
            }}>
              <TextField label="Who?" fullWidth inputRef={crewMemberNameRef} {...crewMemberNameProps} error={!!errors.crewMemberName} helperText={errors.crewMemberName?.message} variant="filled" />
            </div>
            <div>
              <IconButton color="primary" onClick={() => {
                const crewMemberName = getValues('crewMemberName')
                const nextCrewId = getValues('nextCrewId')

                if (!crewMemberName) {
                  return
                }

                if (crewMemberName.trim() === '') {
                  setValue('crewMemberName', null)
                  return
                }

                setValue('crew', [...getValues('crew'), { id: nextCrewId, name: getValues('crewMemberName').trim() }])
                setValue('crewMemberName', null)
                setValue('nextCrewId', nextCrewId + 1)
              }}>
                <ArrowForwardRoundedIcon fontSize="large"/>
              </IconButton>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {crew.map((crewMember) => (
              <Grid item key={crewMember.id} xs={12} sm={6} md={4}>
                <Card variant="outlined">
                  <CardHeader
                    avatar={
                      <Avatar>
                        {crewMember.name.charAt(0).toUpperCase()}
                      </Avatar>
                    }
                    action={
                      <IconButton size="small" onClick={(event) => {
                        // Prevent addition click event on Card after this.
                        event.stopPropagation()
                        setValue('crew', crew.filter((member) => member.id !== crewMember.id))
                      }}>
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    }
                    title={crewMember.name}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item>
              <Button color="primary" variant="contained" onClick={handleSubmit(onSubmit)}>Start a plan</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={4} style={{ marginTop: 64 }}>
        {!!otherEvents.length && (
          <Grid item xs={12}>
            <Typography variant="h4" align="center">Your other events...</Typography>
          </Grid>
        )}
        {otherEvents.map((event) => (
          <Grid item xs={12} sm={6} md={4} spacing={2}>
            <Card>
              <CardActionArea key={`${event.id}-${event.u}`} href={`event/${event.id}?u=${encodeURIComponent(event.u)}`}>
                <CardContent>
                  <Typography variant="h6" style={{ marginBottom: 12 }}>{event.eventName ?? 'No event name'}</Typography>
                  <Grid container spacing={1}>
                    <Grid item><Avatar>{event.name.charAt(0).toUpperCase()}</Avatar></Grid>
                    {event.crew.map((crewMember) => <Grid item><Avatar>{crewMember.name.charAt(0).toUpperCase()}</Avatar></Grid>)}
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default Home
