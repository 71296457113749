import React, { useEffect, useState, useCallback } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { Controller, useForm } from 'react-hook-form'
import LocationAutocomplete from '../LocationAutocomplete'

const useStyles = makeStyles((theme) => ({
  edit: {
    display: 'inline-block',
    padding: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.common.white}`,
    position: 'relative',
    '&:hover': {
      borderStyle: 'dashed',
      borderColor: theme.palette.grey[800],
      cursor: 'pointer',
    },
    '&:hover:before': {
      fontFamily: 'Material Icons',
      content: '"edit"',
      position: 'absolute',
      right: theme.spacing(-1),
      top: theme.spacing(-1),
      backgroundColor: theme.palette.common.white,
      WebkitFontFeatureSettings: '"liga"',
      color: theme.palette.grey[800],
    },
  },
}))

const LocationSeach = ({ defaultValues, onChange, allowEdit, displayProps }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [service, setService] = useState(null)
  const [venues, setVenues] = useState([])
  const [results, setResults] = useState({
    places: [],
    getNextPage: null,
  })

  const formDefaults = useCallback(() => ({
    location: defaultValues?.location,
    locationDetails: defaultValues?.locationDetails,
    tags: defaultValues?.tags ? defaultValues.tags.join(', ') : null,
    lat: null,
    lng: null,
    shortlistedVenues: {},
  }), [defaultValues])

  const { control, formState: { errors }, watch, handleSubmit, getValues, reset, register, setValue } = useForm({
    defaultValues: formDefaults(),
  })
  const { ref: tagsRef, ...tagsProp } = register('tags')

  useEffect(() => {
    setService(new window.google.maps.places.PlacesService(document.createElement('div')))
  }, [])

  useEffect(() => {
    if (!open) {
      return
    }

    reset(formDefaults())

    const initialVenues = defaultValues?.venues ?? []
    const intialShortlistedVenues = {}

    initialVenues.filter((venue) => venue.selected).forEach((venue) => intialShortlistedVenues[venue.place_id] = true)

    setVenues(initialVenues)
    setResults({
      places: [],
      getNextPage: null,
    })
    setValue('shortlistedVenues', intialShortlistedVenues)
  }, [formDefaults, reset, open, defaultValues, setValue])

  const onSubmit = async ({ locationDetails, tags, lat, lng, shortlistedVenues }) => {
    await service.getDetails({
      placeId: Object.keys(shortlistedVenues).find(place_id => shortlistedVenues[place_id]),
      fields: ['formatted_phone_number']
    }, async (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const formatted_phone_number = place.formatted_phone_number

        await onChange({
          location: locationDetails.description,
          locationDetails,
          tags: tags && locationDetails.types.includes('locality') ? tags.split(',').map(tag => tag.trim()) : [],
          venues: venues.map(({ place_id, formatted_address, name, rating, price_level, user_ratings_total }) => {
            const selected = !!shortlistedVenues[place_id]

            return {
              selected,
              place_id,
              formatted_address,
              name,
              rating,
              price_level,
              user_ratings_total,
              ...(selected && formatted_phone_number && { formatted_phone_number })
            }
          }),
          ...(lat && { lat }),
          ...(lng && { lng }),
        })

        setOpen(false)
      }
    })
  }

  const locationDetails = watch('locationDetails')
  const tags = watch('tags')

  const performSearch = async () => {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${locationDetails.description.replace(/\s+/g, '%20')}}&region=au&key=AIzaSyACDR3aY5l1q-qGbsF4hZxH2gdrBRHacKI`)
    const data = await response.json()

    let loc

    if (Array.isArray(data.results) && data.results.length) {
      loc = data.results[0].geometry.location
    }

    setVenues([])
    setValue('lat', loc.lat)
    setValue('lng', loc.lng)

    const request = {
      location: new window.google.maps.LatLng(loc.lat, loc.lng),
      radius: 5000,
      type: 'restaurant',
      query: tags && tags.trim() ? `${tags.trim()} in ${locationDetails.description}` : locationDetails.description,
    }

    service.textSearch(request, (places, status, pagination) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        if (pagination && pagination.hasNextPage) {
          setResults({
            places,
            getNextPage: () => {
              pagination.nextPage()
            }
          })
        }
        else {
          setResults({
            places,
            getNextPage: null,
          })
        }

        setVenues((prevVenues) => [...prevVenues, ...places])
      }
    })
  }

  const displayLocation = () => <Typography variant="h6" {...displayProps}>{getValues('location')}</Typography>

  const displayTags = Array.isArray(locationDetails?.types) && locationDetails.types.includes('locality')

  return (
    <>
      {allowEdit
        ? (
          <Box onClick={() => setOpen(true)} className={classes.edit}>
            {displayLocation()}
          </Box>
        )
        : displayLocation()
      }
      <Dialog open={open} fullScreen onClose={() => {
        setOpen(false)
      }} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Location</DialogTitle>
        <DialogContent>
          <Grid container justifyContent="flex-start" alignItems="flex-end" spacing={2}>
            <Grid item xs={12} md={displayTags ? 6 : 12}>
              <Controller
                control={control}
                name="locationDetails"
                rules={{ required: 'Please enter a location.'}}
                render={({ field: { onChange, value } }) => (
                  <LocationAutocomplete value={value} onChange={onChange} error={!!errors.locationDetails} helperText={errors.locationDetails?.message} />
                )}
              />
            </Grid>
            {displayTags && (
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Anything specific?"
                  variant="filled"
                  inputRef={tagsRef}
                  error={!!errors.description}
                  helperText={errors.tags?.message}
                  placeholder="Pasta, burgers, dessert"
                  {...tagsProp}
                />
              </Grid>
            )}
            <Grid container item xs={12} justifyContent="center">
              <Grid item>
                <Button color="primary" variant="contained" onClick={performSearch}>Search for Restaurants</Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="shortlistedVenues"
                render={({ field: { onChange, value } }) => (
                  <Grid container spacing={4}>
                    {!venues.length
                      ? (
                        <Grid item xs={12}>
                          <Typography>Loading...</Typography>
                        </Grid>
                      )
                      : (<>{venues.map((venue) => {
                          let priceDisplay = 'no price info'
                          let ratingDisplay = 'no rating'

                          if (venue.price_level) {
                            priceDisplay = ''

                            for (let x = 0; x < venue.price_level; x++) {
                              priceDisplay += '$'
                            }
                          }

                          if (venue.user_ratings_total) {
                            ratingDisplay = `${venue.rating} (${venue.user_ratings_total})`
                          }

                          return (
                              <Grid item key={venue.place_id} xs={12} md={6}>
                                <Card variant="outlined" className={classes.card}>
                                  <CardHeader
                                    avatar={
                                      <Avatar>{venue.name.charAt(0).toUpperCase()}</Avatar>
                                    }
                                    action={
                                      <Checkbox
                                        checked={!!value[venue.place_id]}
                                        onChange={(event) => onChange({
                                          // Allow only one selection.
                                          //...value,
                                          [event.target.name]: event.target.checked,
                                        })}
                                        name={venue.place_id}
                                      />
                                    }
                                    title={venue.name}
                                    subheader={venue.formatted_address}
                                  />
                                  <CardContent>
                                    <Typography>{ratingDisplay}, {priceDisplay}</Typography>
                                  </CardContent>
                                </Card>
                            </Grid>
                          )
                        })}
                        {results.getNextPage && (
                          <Grid container item xs={12} justifyContent="center">
                            <Grid item>
                              <Button fullWidth color="primary" variant="contained" onClick={() => results.getNextPage()}>Show More</Button>
                            </Grid>
                          </Grid>
                        )}
                      </>
                      )
                    }
                  </Grid>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default LocationSeach
