import faunadb, { query as q } from 'faunadb'

const client = new faunadb.Client({ secret: 'fnAETdJa_ZACQCamFtSHLtnm_3DEfa7z9eJLgY2-' })

export const getEvent = async (id) => {
  try {
    const response = await client.query(
      q.Get(
        q.Ref(
          q.Collection('event'),
          id,
        ),
      ),
    )

    if (response.name === 'BadRequest') {
      console.log(response)
      return false
    }

    return response
  } catch (error) {
    console.log(error)
    return false
  }
}

export const createEvent = async (data) => {
  try {
    const response = await client.query(
      q.Create(
        q.Collection('event'),
        {
          data,
        }
      )
    )

    if (response.name === 'BadRequest') {
      console.log(response)
      return false
    }

    return response
  } catch (error) {
    console.log(error)
    return false
  }
}

export const updateEvent = async (id, data) => {
  try {
    const response = await client.query(
      q.Update(
        q.Ref(q.Collection('event'), id),
        {
          data,
        },
      ),
    )

    if (response.name === 'BadRequest') {
      console.log(response)
      return false
    }

    return response
  } catch (error) {
    console.log(error)
    return false
  }
}
