import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FilledInput from '@material-ui/core/FilledInput'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Header from '../../components/Header'
import LocationAutocomplete from '../../components/LocationAutocomplete'
import TimeMask from '../../components/TimeMask'

const Details = ({ eventState = {}, setEventState = () => {} }) => {
  const history = useHistory()
  const { register, handleSubmit, control, formState: { errors }, watch } = useForm({
    defaultValues: {
      date: null,
      time: null,
      tags: null,
      location: null,
    }
  })
  const { ref: tagsRef, ...tagsProp } = register('tags')
  const { ref: eventNameRef, ...eventNameProp } = register('eventName', { required: 'Please add an event name.'})
  const onSubmit = async ({ date, time, location, tags, eventName }) => {
    // Thu Nov 18 2021 00:00:00 GMT+1000 (Australian Eastern Standard Time)
    // 18/11/2021, 00:00:00
    const [timePart, periodPart] = time.split(' ')
    const [hourPart, minutePart] = timePart.split(':')
    const hourPartInt = parseInt(hourPart) !== 12 ? parseInt(hourPart) : 0
    const minutePartInt = parseInt(minutePart)
    const periodExtra = periodPart === 'pm' ? 12 * 3600000 : 0

    // Reset to start of day.
    date.setHours(0, 0, 0, 0)

    const evt = {
      ...eventState,
      eventName,
      eventDate: date.getTime() + hourPartInt * 3600000 + minutePartInt * 60000 + periodExtra,
      reminderDate: date.getTime() - 172800000,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Get system timezone.
      location: location.description,
      locationDetails: location,
    }

    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${location.description.replace(/\s+/g, '%20')}}&region=au&key=AIzaSyACDR3aY5l1q-qGbsF4hZxH2gdrBRHacKI`)
    const data = await response.json()

    if (Array.isArray(data.results) && data.results.length) {
      const loc = data.results[0].geometry.location

      evt.lat = loc.lat
      evt.lng = loc.lng
    }

    evt.tags = tags ? tags.split(',').map(tag => tag.trim()) : []

    setEventState(evt)

    history.push('/shortlist')
  }
  const location = watch('location')

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="date"
              rules={{ required: 'Please enter a date.' }}
              render={({ field: { onChange, value } }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    disableToolbar
                    disablePast
                    autoOk
                    variant="inline"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    fullWidth
                    id="date"
                    label="What date?"
                    value={value}
                    invalidDateMessage="Date of event is invalid."
                    minDateMessage="Date of event cannot be earlier than today."
                    onChange={onChange}
                  />
                </MuiPickersUtilsProvider>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="time"
              rules={{ required: 'Please enter a time.' }}
              render={({ field: { onChange, value }}) => (
                <FormControl variant="filled" fullWidth error={!!errors.time}>
                  <InputLabel htmlFor="time">What time?</InputLabel>
                  <FilledInput
                    value={value}
                    onChange={onChange}
                    name="textmask"
                    id="time"
                    inputComponent={TimeMask}
                    placeholder="07:30 pm"
                  />
                  <FormHelperText>{errors.time?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="location"
              rules={{ required: 'Please enter a location.'}}
              render={({ field: { onChange, value } }) => (
                <LocationAutocomplete value={value} onChange={onChange} error={!!errors.location} helperText={errors.location?.message} />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="What for?"
              variant="filled"
              inputRef={eventNameRef}
              error={!!errors.eventName}
              helperText={errors.eventName?.message}
              placeholder="Grandpa's 70th, Date night with Piper, Highschool friends' catchup"
              {...eventNameProp}
            />
          </Grid>
          {Array.isArray(location?.types) && location.types.includes('locality') && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Anything specific?"
                variant="filled"
                inputRef={tagsRef}
                error={!!errors.tags}
                helperText={errors.tags?.message}
                placeholder="Pasta, burgers, dessert"
                {...tagsProp}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item>
                <Button type="submit" color="primary" variant="contained">Let's find something to do</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}

export default Details
