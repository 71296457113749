import React, { useEffect, useState, useCallback } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useStateMachine, createStore } from 'little-state-machine'
import { useParams } from 'react-router-dom'
import DateTime from '../../components/DateTime'
import AddCrewMember from '../../components/AddCrewMember'
import Header from '../../components/Header'
import Repeat from '../../components/Repeat'
import LocationSearch from '../../components/LocationSearch'
import PlaceIcon from '@material-ui/icons/Place'
import EventIcon from '@material-ui/icons/Event'
import CloseIcon from '@material-ui/icons/Close'
import hashFromString from '../../utils/hashFromString'
import { getEvent, updateEvent } from '../../utils/queryEvent'

createStore(
  {
    events: [],
  },
  {
    name: '__piper__',
    storageType: localStorage,
  }
)

function updateLocalEvents(state, payload) {
  if (!payload.id || !payload.u) {
    return state
  }

  if (state.events.some((event) => payload.id === event.id)) {
    return state
  }

  return {
    ...state,
    events: [
      ...state.events,
      payload,
    ],
  };
}

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(2),
  },
  primaryText: {
    color: theme.palette.primary.main,
  },
  avatar: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  organiser: {
    backgroundColor: theme.palette.grey['900'],
  },
  crewMember: {
    backgroundColor: theme.palette.grey['900'],
  },
  user: {
    fontWeight: theme.typography.fontWeightBold,
    cursor: 'pointer',
    height: '100%',
  },
  crew: {
    height:'100%',
  },
  addCrewMember: {
    color: theme.palette.grey['400'],
  },
  center: {
    textAlign: 'center',
  },
}))
const Event = () => {
  const theme = useTheme()
  const { actions } = useStateMachine({ updateLocalEvents })
  const { id } = useParams()
  const classes = useStyles()
  const [ evt, setEvt ] = useState(null)
  const [ unauthorised, setUnauthorised ] = useState(true)
  const urlParams = new URLSearchParams(window.location.search)
  const undecodedUid = urlParams.get('u')
  let uid = null
  try {
    uid = decodeURIComponent(undecodedUid)
  }
  catch (error) {
    console.log(error)
  }

  const fetchEvent = useCallback(async () => {
    if (!id) {
      return
    }

    const getResponse = await getEvent(id)

    if (!getResponse) {
      return false
    }

    setEvt(getResponse.data)
  }, [id])

  const saveEvent = async (data) => {
    const updateResponse = await updateEvent(id, data)

    if (!updateResponse) {
      return false
    }

    setEvt(updateResponse.data)

    return updateResponse
  }

  useEffect(() => {
    fetchEvent()
  }, [fetchEvent])

  useEffect(() => {
    if (evt && uid) {
      if (uid === evt.uid || evt.crew.filter((crewMember) => crewMember.uid === uid).length) {
        actions.updateLocalEvents({ id, u: uid })
        setUnauthorised(false)
        return
      }

      setUnauthorised(true)
    }
  }, [evt, uid, id, actions])

  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const contentDisplay = () => (
    <Grid container spacing={6} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h6" component="span">
          Here are the details for your plan to have&nbsp;
        </Typography>
        <Typography variant="h6" component="span" className={classes.primaryText} pl={1}>
          {evt.eventName}
        </Typography>
        <Typography variant="h6" component="span">
          &nbsp;on&nbsp;
        </Typography>
        <DateTime
          defaultValues={{
            eventDate: evt.eventDate,
            timeZone: evt.timeZone,
          }}
          onChange={async (values) => await saveEvent(values)}
          allowEdit={uid === evt.uid}
          displayProps={{
            component: 'span',
            className: classes.primaryText,
          }}
        />
        <Typography variant="h6" component="span">
          &nbsp;in&nbsp;
        </Typography>
        <LocationSearch
          defaultValues={{ location: evt.location, locationDetails: evt.locationDetails, tags: evt.tags, venues: evt.venues }}
          allowEdit={uid === evt.uid}
          onChange={(locationUpdate) => saveEvent(locationUpdate)}
          displayProps={{
            component: 'span',
            className: classes.primaryText,
          }}
        />
        <Typography variant="h6" component="span">
          &nbsp;with your&nbsp;
        </Typography>
        <Typography variant="h6" component="span" className={classes.primaryText}>
          Crew
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" align="center" className={classes.heading}>The details of your plan...</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h5" align="center" className={classes.heading}>Who?</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4}>
            <Card variant="outlined" style={{ backgroundColor: theme.palette.success.light }}>
              <CardContent>
                <Avatar className={`${classes.avatar} ${classes.organiser}`}>{evt.name.charAt(0).toUpperCase()}</Avatar>
                <Typography variant="body2" align="center">{`${evt.name}${evt.uid === uid ? ' (You)' : ''}`}</Typography>
                <Typography variant="caption" component="p" align="center">{evt.email}</Typography>
              </CardContent>
            </Card>
          </Grid>
          {evt.crew.map((crewMember) => {
            const isUser = crewMember.uid === uid

            return (
              <Grid item xs={6} sm={4} key={crewMember.uid}>
                <Card
                  className={isUser ? classes.user : classes.crew}
                  variant="outlined"
                  style={(`attending_${crewMember.uid}` in evt) ? { backgroundColor: evt[`attending_${crewMember.uid}`] ? theme.palette.success.light : theme.palette.error.light } : null}
                  onClick={async () => {
                    if (crewMember.uid !== uid) {
                      return
                    }

                    await saveEvent({
                      [`attending_${uid}`]: !evt[`attending_${uid}`],
                    })
                  }}
                >
                  <CardContent style={{ position: 'relative' }}>
                    {uid === evt.uid && (
                      <IconButton
                        size="small"
                        style={{ position: 'absolute', top: 4, right: 4 }}
                        onClick={async () =>  saveEvent({ crew: evt.crew.filter((c) => c.uid !== crewMember.uid) })}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                    <Avatar className={`${classes.avatar} ${classes.crewMember}`}>{crewMember.name.charAt(0).toUpperCase()}</Avatar>
                    <Typography variant="body2" align="center" className={isUser ? classes.user : null}>{`${crewMember.name}${isUser ? ' (You)' : ''}`}</Typography>
                    <Typography variant="caption" component="p" align="center">{crewMember.email ?? 'Crew Member'}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
          {uid === evt.uid && (
            <Grid item xs={6} sm={4}>
              <AddCrewMember id={id} onChange={async (newCrewMember) => saveEvent({ crew: [...evt.crew, newCrewMember]})} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} className={classes.center}>
        <Typography variant="h5" className={classes.heading}>When?</Typography>
        <DateTime
          defaultValues={{
            eventDate: evt.eventDate,
            timeZone: evt.timeZone,
          }}
          onChange={async (values) => await saveEvent(values)}
          allowEdit={uid === evt.uid}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h5" align="center" className={classes.heading}>Where?</Typography>
        <Grid container spacing={2}>
          {evt.venues.filter((venue) => venue.selected).map((venue) => (
            <Grid key={venue.place_id} item xs={12}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <PlaceIcon fontSize="large"/>
                </Grid>
                <Grid item>
                  <Typography variant="h6" align="center" component="span">{venue.name}, {venue.formatted_phone_number}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h5" align="center" className={classes.heading}>What?</Typography>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <EventIcon fontSize="large"/>
            </Grid>
            <Grid item>
              <Typography variant="h6" component="span">{evt.eventName}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {uid === evt.uid && (
        <>
          <Grid item container xs={12} justifyContent="center">
            <Typography variant="h5" align="center" className={classes.heading}>Repeat?</Typography>
            <Repeat
              defaultValues={{
                repeat: evt.repeat,
                repeatValue: evt.repeatValue,
                repeatUnit: evt.repeatUnit,
              }}
              onChange={async (values) => await saveEvent(values)}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>Share the plan</Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
              <DialogTitle id="form-dialog-title">Share your plan</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  {evt.crew.map((crewMember, index) => (
                    <Grid item xs={12}>
                      <TextField
                        key={crewMember.uid}
                        autoFocus
                        variant="filled"
                        label={`${crewMember.name}'s Email`}
                        onChange={(event) => {
                          const updatedEvt = {...evt}
                          updatedEvt.crew[index].email = event.target.value
                          setEvt(updatedEvt)
                        }}
                        type="email"
                        defaultValue={crewMember.email ?? ''}
                        fullWidth
                      />
                    </Grid>
                  ))}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={async () => {
                    const newCrew = []

                    for (let i = 0; i < evt.crew.length; i += 1) {
                      newCrew.push({
                        ...evt.crew[i],
                        uid: await hashFromString(`${id}${evt.crew[i].email}`),
                      })
                    }

                    const response = await saveEvent({ crew: newCrew })

                    if (response) {
                      const request = new XMLHttpRequest()
                      request.onreadystatechange = function () {
                        if (this.readyState === 4 && this.status === 200) {
                          handleClose()
                        }
                      }
                      request.open('GET', `https://piper-cron.herokuapp.com/share?id=${id}`, true)
                      request.send()
                    }
                  }}
                >
                  Share
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </>
      )}
    </Grid>
  )

  const unauthorisedDisplay = () => (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="body1" align="center">
          Please click on the link on the invite email to be able to view the event.
        </Typography>
      </Grid>
    </Grid>
  )

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12}>
          {unauthorised && evt && unauthorisedDisplay()}
          {!unauthorised && evt && contentDisplay()}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Event
