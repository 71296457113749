import React from 'react'
import Typography from '@material-ui/core/Typography'

const Header = () => (
  <>
    <Typography variant="h1" align="center" style={{ fontWeight: 'bold'}}>piper</Typography>
    <Typography variant="h5" align="center">See your important</Typography>
    <Typography variant="h5" align="center">people more often</Typography>
  </>
)

export default Header
