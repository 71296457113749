import React, { useEffect, useState, useCallback } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { Controller, useForm } from 'react-hook-form'
import LocationAutocomplete from '../LocationAutocomplete'

const useStyles = makeStyles((theme) => ({
  edit: {
    display: 'inline-block',
    padding: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.common.white}`,
    position: 'relative',
    '&:hover': {
      borderStyle: 'dashed',
      borderColor: theme.palette.grey[800],
      cursor: 'pointer',
    },
    '&:hover:before': {
      fontFamily: 'Material Icons',
      content: '"edit"',
      position: 'absolute',
      right: theme.spacing(-1),
      top: theme.spacing(-1),
      backgroundColor: theme.palette.common.white,
      WebkitFontFeatureSettings: '"liga"',
      color: theme.palette.grey[800],
    },
  },
}))

const LocationEdit = ({ defaultValues, onChange, allowEdit, displayProps }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const formDefaults = useCallback(() => ({
    location: defaultValues?.location,
    locationDetails: defaultValues?.locationDetails,
    tags: defaultValues?.tags ? defaultValues.tags.join(', ') : null,
  }), [defaultValues])

  const { control, formState: { errors }, watch, handleSubmit, getValues, reset, register } = useForm({
    defaultValues: formDefaults(),
  })
  const { ref: tagsRef, ...tagsProp } = register('tags')

  useEffect(() => {
    reset(formDefaults())
  }, [formDefaults, reset, open])

  const onSubmit = async ({ locationDetails, tags }) => {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${locationDetails.description.replace(/\s+/g, '%20')}}&region=au&key=AIzaSyACDR3aY5l1q-qGbsF4hZxH2gdrBRHacKI`)
    const data = await response.json()

    let loc

    if (Array.isArray(data.results) && data.results.length) {
      loc = data.results[0].geometry.location
    }

    await onChange({
      location: locationDetails.description,
      locationDetails,
      tags: tags && locationDetails.types.includes('locality') ? tags.split(',').map(tag => tag.trim()) : [],
      lat: loc.lat,
      lng: loc.lng,
    })

    setOpen(false)
  }

  const locationDetails = watch('locationDetails')

  const displayLocation = () => <Typography variant="h6" {...displayProps}>{getValues('location')}</Typography>

  return (
    <>
      {allowEdit
        ? (
          <Box onClick={() => setOpen(true)} className={classes.edit}>
            {displayLocation()}
          </Box>
        )
        : displayLocation()
      }
      <Dialog open={open} onClose={() => {
        setOpen(false)
      }} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Location</DialogTitle>
        <DialogContent>
          <Grid container justifyContent="center" alignItems="flex-end" spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="locationDetails"
                rules={{ required: 'Please enter a location.'}}
                render={({ field: { onChange, value } }) => (
                  <LocationAutocomplete value={value} onChange={onChange} error={!!errors.locationDetails} helperText={errors.locationDetails?.message} />
                )}
              />
            </Grid>
            {Array.isArray(locationDetails?.types) && locationDetails.types.includes('locality') && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Anything specific?"
                  variant="filled"
                  inputRef={tagsRef}
                  error={!!errors.description}
                  helperText={errors.tags?.message}
                  placeholder="Pasta, burgers, dessert"
                  {...tagsProp}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default LocationEdit
