import MaskedInput from 'react-text-mask'

const TimeMask = ({ inputRef, ...other }) => (
  <MaskedInput
    {...other}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={[/[0-1]/, /\d/, ':', /[0-5]/, /\d/, ' ', /[a|p]/, 'm']}
    placeholderChar={'_'}
  />
)

export default TimeMask
