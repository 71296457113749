import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useForm } from 'react-hook-form'
import Header from '../../components/Header'
import hashFromString from '../../utils/hashFromString'
import { createEvent, updateEvent } from '../../utils/queryEvent'

const Confirm = ({ eventState = {} }) => {
  // States: open, submitting, submitted.
  const [formStatus, setFormStatus] = useState('open')
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      name: '',
      email: '',
    },
  })

  const { ref: name, ...nameProps } = register('name', { required: 'Please enter your name.' })
  const { ref: email, ...emailProps } = register('email', { required: 'Please enter your email.' })

  const onSubmit = async (values) => {
    setFormStatus('submitting')
    const createResponse = await createEvent({
      ...eventState,
      name: values.name,
      email: values.email,
    })

    if (!createResponse) {
      return false
    }

    const id = createResponse.ref.id

    const updateResponse = await updateEvent(id, {
      uid: await hashFromString(`${id}${createResponse.data.email}`),
    })

    if (!updateResponse) {
      return false
    }

    const request = new XMLHttpRequest()

    request.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        setFormStatus('submitted')
      }
    }
    request.open('GET', `https://piper-cron.herokuapp.com/confirm?id=${id}`, true)
    request.send()
  }

  const isSubmitting = formStatus === 'submitting'

  return (
    <Container>
      {formStatus !== 'submitted' ? (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Your name?" fullWidth inputRef={name} {...nameProps} error={!!errors.name} helperText={errors.name?.message} variant="filled" />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Your email?" fullWidth inputRef={email} {...emailProps} error={!!errors.email} helperText={errors.email?.message} variant="filled" />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                  disabled={isSubmitting}
                  startIcon={isSubmitting && <CircularProgress size={16} color="inherit"/>}
                >{isSubmitting ? 'Submitting plan...' : 'Start a plan'}</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center">Please check your inbox for the confirmation email.</Typography>
          </Grid>
          <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item>
                  <Button type="submit" color="primary" variant="contained">Resend confirmation email</Button>
                </Grid>
              </Grid>
            </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default Confirm
