import React, { useEffect, useState, useCallback } from 'react'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { Controller, useForm } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center'
  },
  edit: {
    display: 'inline-block',
    padding: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.common.white}`,
    position: 'relative',
    '&:hover': {
      borderStyle: 'dashed',
      borderColor: theme.palette.grey[800],
      cursor: 'pointer',
    },
    '&:hover:before': {
      fontFamily: 'Material Icons',
      content: '"edit"',
      position: 'absolute',
      right: theme.spacing(-1),
      top: theme.spacing(-1),
      backgroundColor: theme.palette.common.white,
      WebkitFontFeatureSettings: '"liga"',
      color: theme.palette.grey[800],
    },
  },
}))

const Repeat = ({ defaultValues, onChange, displayProps }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const formDefaults = useCallback(() => ({
    repeat: defaultValues?.repeat ?? false,
    repeatValue: defaultValues?.repeatValue ?? 1,
    repeatUnit: defaultValues?.repeatUnit ?? 'day',
  }), [defaultValues])

  const { control, handleSubmit, register, reset, watch } = useForm({
    defaultValues: formDefaults(),
  })
  const { ref: repeatValueRef, ...repeatValueProps } = register('repeatValue')
  const repeat = watch('repeat')
  let repeatDisplay = 'No'

  if (defaultValues?.repeat) {
    repeatDisplay = `Repeat event every ${defaultValues.repeatValue > 1 ? `${defaultValues.repeatValue} ` : ''}${defaultValues?.repeatUnit ?? 'day'}${defaultValues.repeatValue > 1 ? 's' : ''}`
  }

  useEffect(() => {
    reset(formDefaults())
  }, [formDefaults, reset, open])

  const onSubmit = async ({ repeat, repeatValue, repeatUnit }) => {
    await onChange({ repeat, repeatValue, repeatUnit })
    setOpen(false)
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} className={classes.center}>
        <Box onClick={() => setOpen(true)} textAlign="center" className={classes.edit}>
          <Typography variant="h6" {...displayProps}>{repeatDisplay}</Typography>
        </Box>
        <Dialog open={open} onClose={() => {
          setOpen(false)
        }} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
          <DialogTitle id="form-dialog-title">Edit Repeat</DialogTitle>
          <DialogContent>
            <Grid container justifyContent="center" alignItems="flex-end" spacing={2}>
              <Grid item>
                <Controller
                  control={control}
                  name="repeat"
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      label={`Repeat${repeat ? ' every' : '' }`}
                      control={
                        <Checkbox
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              {repeat && (
                <>
                  <Grid item>
                    <TextField
                      fullWidth
                      variant="filled"
                      inputProps={{ type: 'number', min: 1 }}
                      inputRef={repeatValueRef}
                      {...repeatValueProps}
                    />
                  </Grid>
                  <Grid item>
                    <Controller
                      control={control}
                      name="repeatUnit"
                      render={({ field: { onChange, value } }) => (
                        <FormControl variant="filled">
                          <Select
                            value={value}
                            onChange={onChange}
                          >
                            <MenuItem value="month">month</MenuItem>
                            <MenuItem value="week">week</MenuItem>
                            <MenuItem value="day">day</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  )
}

export default Repeat
