import React, { useEffect, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { format, utcToZonedTime } from 'date-fns-tz'
import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Header from '../../components/Header'
import LocationEdit from '../../components/LocationEdit'

const useStyles = makeStyles((theme) => ({
  primaryText: {
    color: theme.palette.primary.main,
  },
  card: {
    height: '100%',
  }
}))

const Shortlist = ({ eventState = {}, setEventState ={} }) => {
  const history = useHistory()
  const [service, setService] = useState(null)
  const [venues, setVenues] = useState([])
  const [results, setResults] = useState({
    places: [],
    getNextPage: null,
  })
  const classes = useStyles()

  useEffect(() => {
    setService(new window.google.maps.places.PlacesService(document.createElement('div')))
  }, [])

  useEffect(() => {
    if (!service) {
      return
    }

    setVenues([])

    const request = {
      location: new window.google.maps.LatLng(eventState.lat, eventState.lng),
      radius: 5000,
      type: 'restaurant',
      query: eventState.tags.length ? `${eventState.tags.join(', ')} in ${eventState.location}` : `${eventState.location}`,
    }

    service.textSearch(request, (places, status, pagination) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        if (pagination && pagination.hasNextPage) {
          setResults({
            places,
            getNextPage: () => {
              pagination.nextPage()
            }
          })
        }
        else {
          setResults({
            places,
            getNextPage: null,
          })
        }

        setVenues((prevVenues) => [...prevVenues, ...places])
      }
    })
  }, [eventState.lat, eventState.lng, eventState.location, eventState.tags, service])

  const { handleSubmit, control } = useForm({
    defaultValues: {
      shortlistedVenues: {},
      reminderDate: new Date(),
      repeat: false,
      repeatInterval: 3,
      repeatUnit: 'month',
    }
  })

  const onSubmit = ({ shortlistedVenues }) => {
    service.getDetails({
      placeId: Object.keys(shortlistedVenues).find(place_id => shortlistedVenues[place_id]),
      fields: ['formatted_phone_number']
    }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const formatted_phone_number = place.formatted_phone_number

        const evt = {...eventState,
          venues: venues.map(({ place_id, formatted_address, name, rating, price_level, user_ratings_total }) => {
            const selected = !!shortlistedVenues[place_id]

            return {
              selected,
              place_id,
              formatted_address,
              name,
              rating,
              price_level,
              user_ratings_total,
              ...(selected && formatted_phone_number && { formatted_phone_number })
            }
          }),
        }

        setEventState(evt)

        history.push('/confirm')
      }
    })
  }

  const zonedTime = utcToZonedTime(new Date(eventState.eventDate), eventState.timeZone)
  const formattedTime = format(zonedTime, "EEEE, do 'of' MMMM @ p")

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="span">
            Here are some ideas your event <span className={classes.primaryText}>{eventState.eventName}</span> on <span className={classes.primaryText}>{formattedTime}</span> in&nbsp;
          </Typography>
          <LocationEdit
            defaultValues={{ location: eventState.location, locationDetails: eventState.locationDetails, tags: eventState.tags }}
            allowEdit
            onChange={(locationUpdate) => setEventState({
              ...eventState,
              ...locationUpdate,
            })}
            displayProps={{
              component: 'span',
              className: classes.primaryText,
            }}
          />
          <Typography variant="h6" component="span">
            &nbsp;with your <span className={classes.primaryText}>Crew</span>
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Controller
            control={control}
            name="shortlistedVenues"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Grid container spacing={4}>
                {!venues.length
                  ? (
                    <Grid item xs={12}>
                      <Typography>Loading...</Typography>
                    </Grid>
                  )
                  : venues.map((venue) => {
                      let priceDisplay = 'no price info'
                      let ratingDisplay = 'no rating'

                      if (venue.price_level) {
                        priceDisplay = ''

                        for (let x = 0; x < venue.price_level; x++) {
                          priceDisplay += '$'
                        }
                      }

                      if (venue.user_ratings_total) {
                        ratingDisplay = `${venue.rating} (${venue.user_ratings_total})`
                      }

                      return (
                          <Grid item key={venue.place_id} xs={12} md={6}>
                            <Card variant="outlined" className={classes.card}>
                              <CardHeader
                                avatar={
                                  <Avatar>{venue.name.charAt(0).toUpperCase()}</Avatar>
                                }
                                action={
                                  <Checkbox
                                    checked={!!value[venue.place_id]}
                                    onChange={(event) => onChange({
                                      // Allow only one selection.
                                      //...value,
                                      [event.target.name]: event.target.checked,
                                    })}
                                    name={venue.place_id}
                                  />
                                }
                                title={venue.name}
                                subheader={venue.formatted_address}
                              />
                              <CardContent>
                                <Typography>{ratingDisplay}, {priceDisplay}</Typography>
                              </CardContent>
                            </Card>
                        </Grid>
                      )
                    })
                }
                {results.getNextPage && (
                  <Grid item xs={12}>
                    <Button fullWidth color="primary" variant="contained" onClick={() => results.getNextPage()}>Show More</Button>
                  </Grid>
                )}
              </Grid>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button fullWidth color="primary" variant="contained" onClick={handleSubmit(onSubmit)}>Submit</Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Shortlist
