import React, { useEffect, useState, useCallback } from 'react'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FilledInput from '@material-ui/core/FilledInput'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { Controller, useForm } from 'react-hook-form'
import { format, utcToZonedTime } from 'date-fns-tz'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import TimeMask from '../TimeMask'

const useStyles = makeStyles((theme) => ({
  edit: {
    display: 'inline-block',
    padding: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.common.white}`,
    position: 'relative',
    '&:hover': {
      borderStyle: 'dashed',
      borderColor: theme.palette.grey[800],
      cursor: 'pointer',
    },
    '&:hover:before': {
      fontFamily: 'Material Icons',
      content: '"edit"',
      position: 'absolute',
      right: theme.spacing(-1),
      top: theme.spacing(-1),
      backgroundColor: theme.palette.common.white,
      WebkitFontFeatureSettings: '"liga"',
      color: theme.palette.grey[800],
    },
  },
}))

const DateTime = ({ defaultValues, onChange, allowEdit, displayProps }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const formDefaults = useCallback(() => {
    const zonedTime = utcToZonedTime(new Date(defaultValues?.eventDate), defaultValues?.timeZone)

    return {
      date: zonedTime,
      time: format(zonedTime, 'hh:mm aaa').toLowerCase(),
    }
  }, [defaultValues])

  const { control, formState: { errors }, getValues, handleSubmit, reset } = useForm({
    defaultValues: formDefaults(),
  })

  useEffect(() => {
    reset(formDefaults())
  }, [formDefaults, reset, open])

  const onSubmit = async ({ date, time }) => {
    const [timePart, periodPart] = time.split(' ')
    const [hourPart, minutePart] = timePart.split(':')
    const hourPartInt = parseInt(hourPart) !== 12 ? parseInt(hourPart) : 0
    const minutePartInt = parseInt(minutePart)
    const periodExtra = periodPart === 'pm' ? 12 * 3600000 : 0

    // Reset to start of day.
    date.setHours(0, 0, 0, 0)

    await onChange({ eventDate: date.getTime() + hourPartInt * 3600000 + minutePartInt * 60000 + periodExtra, reminderDate: date.getTime() - 172800000 })
    setOpen(false)
  }

  const displayDateTime = () => <Typography variant="h6" {...displayProps}>{format(getValues('date'), "EEEE, do 'of' MMMM @ p")}</Typography>

  return (
    <>
      {allowEdit
        ? (
          <Box onClick={() => setOpen(true)} className={classes.edit}>
            {displayDateTime()}
          </Box>
        )
        : displayDateTime()
      }
      <Dialog open={open} onClose={() => {
        setOpen(false)
      }} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Edit When?</DialogTitle>
        <DialogContent>
          <Grid container justifyContent="center" alignItems="flex-end" spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="date"
                rules={{ required: 'Please enter a date.' }}
                render={({ field: { onChange, value } }) => (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      disablePast
                      autoOk
                      variant="inline"
                      inputVariant="filled"
                      format="dd/MM/yyyy"
                      fullWidth
                      id="date"
                      label="What date?"
                      value={value}
                      invalidDateMessage="Date of event is invalid."
                      minDateMessage="Date of event cannot be earlier than today."
                      onChange={onChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="time"
                rules={{ required: 'Please enter a time.' }}
                render={({ field: { onChange, value }}) => (
                  <FormControl variant="filled" fullWidth error={!!errors.time}>
                    <InputLabel htmlFor="time">What time?</InputLabel>
                    <FilledInput
                      value={value}
                      onChange={onChange}
                      name="textmask"
                      id="time"
                      inputComponent={TimeMask}
                      placeholder="07:30 pm"
                    />
                    <FormHelperText>{errors.time?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DateTime
