import React, { useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { StateMachineProvider } from 'little-state-machine'
import Confirm from './step/Confirm'
import Details from './step/Details'
import Event from './step/Event'
import Home from './step/Home'
import Shortlist from './step/Shortlist'

const theme = createTheme({
  overrides: {
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 16,
        },
      }
    },
  },
});

const App = () => {
  const [eventState, setEventState] = useState({})

  return (
    <ThemeProvider theme={theme}>
      <StateMachineProvider>
        <Router>
          <Switch>
            <Route exact path="/">
              <Home eventState={eventState} setEventState={setEventState} />
            </Route>
            <Route path="/details">
              <Details eventState={eventState} setEventState={setEventState} />
            </Route>
            <Route path="/shortlist">
              <Shortlist eventState={eventState} setEventState={setEventState} />
            </Route>
            <Route path="/confirm">
              <Confirm eventState={eventState} setEventState={setEventState} />
            </Route>
            <Route path="/event/:id">
              <Event />
            </Route>
          </Switch>
        </Router>
      </StateMachineProvider>
    </ThemeProvider>
  )
}

export default App
