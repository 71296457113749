import React, { useEffect, useState, useCallback } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'
import hashFromString from '../../utils/hashFromString'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  content: {
    height: '100%',
    minHeight: 100,
    padding: 0,
  },
  icon: {
    color: theme.palette.grey['400'],
  },
}))

const AddCrewMember = ({ id, onChange }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const formDefaults = useCallback(() => ({
    name: '',
    email: '',
  }), [])

  const { formState: { errors }, handleSubmit, register, reset } = useForm({
    defaultValues: formDefaults(),
  })

  const { ref: name, ...nameProps } = register('name', { required: 'Please enter your name.' })
  const { ref: email, ...emailProps } = register('email', { required: 'Please enter your email.' })


  useEffect(() => {
    reset(formDefaults())
  }, [formDefaults, reset, open])

  const onSubmit = async ({ name, email }) => {
    await onChange({ name, email, uid: await hashFromString(`${id}${email}`), })
    setOpen(false)
  }

  return (
    <>
      <Card
        className={classes.container}
        variant="outlined"
        onClick={async () => {
          setOpen(true)
        }}
      >
        <CardContent className={classes.content}>
          <Grid container justifyContent="center" alignItems="center" className={classes.container}>
            <Grid item>
              <AddIcon className={classes.icon}/>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Dialog open={open} onClose={() => {
        setOpen(false)
      }} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Add Crew Member</DialogTitle>
        <DialogContent>
          <Grid container justifyContent="center" alignItems="flex-end" spacing={2}>
            <Grid item xs={12}>
              <TextField label="Name" fullWidth inputRef={name} {...nameProps} error={!!errors.name} helperText={errors.name?.message} variant="filled" />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Email" fullWidth inputRef={email} {...emailProps} error={!!errors.email} helperText={errors.email?.message} variant="filled" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddCrewMember
